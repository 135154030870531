<template>
  <div>
    <b-row>
      <b-col md="8">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Véhicules</b-card-title>
            <b-button size="sm" :disabled="!editable" v-b-modal.modal-sm variant="primary">Ajouter des véhicules</b-button>
          </b-card-header>
          <b-card-body>
            <div v-for="item in offre.vehicules">
              <div class="d-flex justify-content-between">
                <div class="position-relative">
                  <vehicule-overview :vehicule="item.vehicule" />
                  <b-button v-if="editable" @click="supprimer_vehicule(item)" size="sm" variant="danger" style="left: 18px" class="position-absolute position-bottom-0 btn-icon"><feather-icon icon="XIcon" /></b-button>
                </div>
                <div>
                  <b-form-group label="Prix : " >
                    <b-form-input v-model="item.prix" :disabled="!editable" />
                  </b-form-group>
                </div>
              </div>
              
              <hr />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card title="Récapitulatif">
          
          <h6>Prix total des véhicules (TTC) : {{total_prix_vehicule.toLocaleString('fr')}} Dhs </h6> 
          <hr />
          <h6>Frais de la livraison (TTC) :</h6>
          <b-form-input :disabled="!editable" v-model="offre.frais_livraison" type="number" />
          <hr />
          <h4>Net à payer : {{((total_prix_vehicule * 1) + (offre.frais_livraison * 1)).toLocaleString('en')}} Dhs</h4>
        </b-card>
        <client-card @client-selected="selecte_client"  :editable="editable" :initial-client="offre.client" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
   <b-card>
 
     <div class="text-right">
        <div class="d-flex justify-content-between">
 
          <div>
            <b-dropdown
                split
                text="Imprimer"
                variant="primary"
                right
                v-if="offre.documents.length"
            >
              <!--      <b-dropdown-item target="_blank" :href="item.path" v-for="item in offre.documents">-->
              <!--        {{item.title}}-->
              <!--      </b-dropdown-item>-->
              <b-dropdown-item-button  @click="printClickHandler(item)" v-for="item in offre.documents">
                {{ item.title }}
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
          <div>


            <b-button @click="enregistrer"  :disabled="!editable" variant="primary">
              <span v-if="offre.id">Enregsitrer</span>
              <span v-else>Créer l'offre</span>
            </b-button>
            <b-button v-if="offre.id" :disabled="offre.str_statut.str_id != 'en_cours'" class="ml-1" @click="valider_offre_marchand" variant="success">Valider</b-button>
            <b-button v-if="offre.id"  class="ml-1" @click="annuler_offre_marchand" variant="danger">Annuler</b-button>

          </div>
        </div>
     </div>
     
   </b-card>
      </b-col>
    </b-row>
    <b-modal
        id="modal-sm"
        cancel-variant="secondary"
        ok-title="Ajouter"
        cancel-title="Annuler"
        centered
        @show="resetModal"
        @ok="handleOk"
        size="xl"
        title="sélectionner des véhicules"
    >
      <div>

      </div>
      <div>
        <b-row>
         <b-col>
           <b-form @submit.prevent="fetchVehicules(0)">
             <b-input-group class="mb-1">
               <b-form-input    v-model="filter.q"     placeholder="Recherche" />
               <b-input-group-append>
                 <b-button  @click="fetchVehicules(1)" variant="outline-primary">
                   Rechercher
                 </b-button>
               </b-input-group-append>
             </b-input-group>
           </b-form>
     
         </b-col>
        </b-row>
        <b-row>
          <b-col md="4" :key="item.id" v-for="item in vehicules_marchands">
            <div :class="{selected : (selected_vehicules.filter(v => v.id == item.id )).length, choosed : offre.vehicules.filter(i => i.id ==item.id).length}" @click="selectVehiculeHandler(item)" class="vehicule-item cursor-pointer d-flex justify-content-between">
              <vehicule-overview border-variant="border-left" :with-image="false" :vehicule="item.vehicule"/>
              <div class="font-weight-bold font-medium-1">
                {{ item.prix.toLocaleString('fr') }} Dhs
              </div>
            </div>
            <hr/>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-pagination
            v-model="pagination.current_page"
            :total-rows="pagination.total"
            :per-page="pagination.per_page"
            first-number
            last-number
            @change="fetchVehicules"
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/libs/axios";
import VehiculeOverview from "@/components/Vehicule/VehiculeOverview";
import ClientCard from "@/components/ClientCard";

export default {
  name: "MarchandOffreView",
  components: {ClientCard, VehiculeOverview},
  data() {
    return {
      selected_vehicules : [],
      filter : {},
      pagination : {},
      vehicules_marchands: [],
      offre: {
        vehicules: [],
        frais_livraison: 0,
        prix: 0,
        client: {}
      }
    };
  },
  computed : {
    editable(){

      if(!this.offre.id){
        return true;
      }
      
      if(this.offre.str_statut.str_id == "en_cours"){
        return true;
      }

      return false;
    },
    total_prix_vehicule(){
      let total = 0;
      this.offre.vehicules.forEach(function(item){
        total+= (item.prix * 1);
      });
      return total;
    },
  },
  created() {
    console.log(this.$route.params);
    if(this.$route.params.id){
      let _this = this;
      axios.get('/api/marchand/' + this.$route.params.id).then(function (response) {
        
        _this.offre = response.data.data;
        console.log(response.data.data);
      });
    }
  },
  methods: {
    printClickHandler(){
      console.log('---');
    },
    fetchVehicules(page){
      let _this = this;
      axios.get('/api/marchands/vehicules',{
        params : {
          page: page,
          ..._this.filter
        }
      }).then(function (response) {
        _this.vehicules_marchands = response.data.data;
        _this.pagination = response.data.meta;
        console.log(response.data.data);
      });
    },
    selectVehiculeHandler(item){
      if(this.offre.vehicules.filter(i => i.id ==item.id).length){
        
        return false;
      }
      if(this.selected_vehicules.filter(i => i.id ==item.id).length){
        
        this.selected_vehicules = this.selected_vehicules.filter(i => i.id != item.id)
      }else{
        this.selected_vehicules.push(item);
        
      }
      
    },
    resetModal() {
      this.fetchVehicules(1);
    },
    supprimer_vehicule(item){
      let _this = this;
      this.$bvModal
          .msgBoxConfirm('Voulez vous vraiment supprimer le véhicule ?', {
            title: 'Supprimer véhicule',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              _this.offre.vehicules = _this.offre.vehicules.filter(function(vehicule){
                return vehicule.id != item.id;
              });
            }
          });
   
    },
    handleOk(bvModalEvent) {
      let _this = this;

      this.selected_vehicules.forEach(function (item){
        _this.offre.vehicules.push(item);
      });
      
      //bvModalEvent.preventDefault()

    },
    selecte_client(client){
      this.offre.client = client;
    },
    enregistrer(){
      let _this = this;
      axios.post('/api/marchand/store', {
        offre : this.offre
      }).then(function(response){
        _this.offre =response.data.data; 
        console.log(response.data.data)
      });
 
    },
    valider_offre_marchand(){
      let _this = this;
      this.$bvModal
          .msgBoxConfirm('Voulez vous vraiment valider l\'offre ?', {
            title: 'Supprimer véhicule',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              axios.post('/api/marchand/' + this.offre.id + '/valider').then(function(response){
                _this.offre =response.data.data;
                console.log(response.data.data)
              });
            }
          });
    

    },
    annuler_offre_marchand(){
      let _this = this;
      this.$bvModal
          .msgBoxConfirm('Voulez vous vraiment annuler l\'offre ?', {
            title: 'Supprimer véhicule',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              axios.post('/api/marchand/' + this.offre.id + '/annuler').then(function(response){
                _this.offre =response.data.data;
                console.log(response.data.data)
              });
            }
          });
   
    }
  }
}
</script>

<style lang="scss" scoped>
  .vehicule-item{
    padding: 12px;
  }
  .selected{
    background-color: aliceblue;
    .blockquote{
      border-color: #28c76f !important;
    }
  }
  .choosed{
    background-color: aliceblue;
    .blockquote{
      border-color:#7367f0 !important;
    }
  }
</style>