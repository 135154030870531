<template>
  <div>
    <b-card  :class="{'bg-light-danger' : errors.length}" no-body title="Client">
      <b-card-header>
        <b-card-title>Client</b-card-title>
        <b-button :disabled="!editable" @click="show_modal" size="sm" variant="flat-primary">
          <feather-icon
              icon="EditIcon"
              class="mr-50"
          />
          <span v-if="initialClient.id" class="align-middle">Modifier</span>
          <span v-else class="align-middle">Ajouter</span>
        </b-button>
      </b-card-header>
      <b-card-body v-if="initialClient.id">
        <div v-if="initialClient.full_name"><feather-icon icon="UserIcon"></feather-icon> <strong>{{ initialClient.full_name }}</strong></div>
        <div v-if="initialClient.telephone"><feather-icon icon="PhoneCallIcon"></feather-icon> <strong>{{ initialClient.telephone }}</strong></div>
        <div v-if="initialClient.email"><feather-icon icon="MailIcon"></feather-icon>  <strong>{{ initialClient.email }}</strong></div>
        <div v-if="initialClient.adresse"><p class="mb-0"><feather-icon icon="MapPinIcon"></feather-icon> <strong>{{ initialClient.adresse }}</strong></p></div>
      </b-card-body>
      <!--        <v-select @search="fetchClients" class="mb-2" id="client" v-model="offre.client" :options="clients"-->
      <!--                  label="full_name"/>-->


    </b-card>
 

    <b-modal
        id="modal-clients"
        cancel-variant="outline-secondary"
        ok-title="Valider"
        cancel-title="Annuler"
        centered
        size="lg"
        ref="ref-modal-clients"
        :title="'Client ' + (total_rows != 0 ? ('(' + total_rows + ')') : '' )"
        @ok.prevent="handleOk"
    >
      <div v-if="!show_form">
        <b-form @submit.prevent="fetchClients(0)">
          
          <div>
            <label>Rechercher votre client  ou  <a href="javascript:void(0)" @click="show_form = true" variant="flat-primary" >Ajouter un client</a> </label>
            <b-form-input v-model="search" placeholder="Nom, Prénom, CIN, Téléphone"/>
          </div>
 
          
        </b-form>
        <table class="table table-sm table-hover">
          <tbody>
          <tr @click="selected = item" :class="{selected : item.id == selected.id}" v-for="item in clients" >
            <td>
              <div>{{item.full_name}}</div>
              <div class="text-muted">{{item.telephone}}</div>
            </td>
            <td>{{item.email}}</td>
          </tr>
          </tbody>
        </table>
        <div class="text-center" v-if="clients.length == 0">
          <hr/>
         
        </div>
        <b-pagination v-if="clients.length"
                      pills
                      size="sm"
                      @change="fetchClients"
                      hide-goto-end-buttons
                      :total-rows="total_rows"
                      align="center"
        />
      </div>
      <client-form v-if="show_form" @submited="setClient" ref="popup_client" :show-save-button="false" />
    </b-modal>
    
    
    
  </div>

 
</template>

<script>
import ClientForm from "@/components/ClientForm";
export default {
  name: "ClientCard",
  components: {ClientForm},
  props : {
    errors : {
      type : Array,
      default (){
        return []
      }
    },
    editable : {
      default : true,
      type : Boolean
    },
    initialClient : {
      type : Object,
      required : true,
      default (){
        return {}
      }
    }
  },
  
  data(){
    return {
      clients : [],
      client : false,
      search : '',
      total_rows : 0,
      selected : {},
      show_form : false
    }
  },
  mounted() {
    this.client = this.initialClient;
  },
  methods : {
    show_modal(){
      this.show_form = false;
      this.$nextTick(() => {
        this.$bvModal.show('modal-clients')
      })
    },
    handleOk(){
      
      
      if(!this.show_form){
        if(this.selected.id){
          this.$emit('client-selected', this.selected)
          this.$nextTick(() => {
            this.$bvModal.hide('modal-clients')
          })
        }
      }else{
        this.$refs.popup_client.submit();
      }
      
    },
    setClient(client){
      this.$emit('client-selected', client );
      this.$bvModal.hide('modal-clients')
    },
    fetchClients(page = 0) {
      let _this = this;
      this.$store.dispatch('clientsModule/fetchClients', {
        'q': this.search,
        'page' : page,
        'per_page' : 5
      }).then(function (response) {
        _this.clients = response.data;
        _this.total_rows = response.meta.total;
      })
    },
  }
}
</script>

<style scoped>
  .selected{
    background-color: #f6f6f9;
  }
</style>