<template>

    <b-form @submit.prevent>
      <b-row>
        <b-col md="4">
          <b-form-group
              label="Civilite"
              label-for="civilite"
          >
            <b-form-select
                v-model="client.civilite"
                id="civilite"
                placeholder="Civilite"
            >
              <b-form-select-option value="Mr">Mr</b-form-select-option>
              <b-form-select-option value="Mme">Mme</b-form-select-option>
              <b-form-select-option value="Ste">Ste</b-form-select-option>
              
            </b-form-select>
            
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label="Nom"
              label-for="nom"
          >
            <b-form-input
                v-model="client.nom"
                id="nom"
                placeholder="Nom"
            />
            <small v-if="errors.nom" class="text-danger">{{ errors.nom[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label="Prenom"

              label-for="prenom"
          >
            <b-form-input
                id="prenom"
                v-model="client.prenom"
                placeholder="Prenom"
            />
            <small v-if="errors.prenom" class="text-danger">{{ errors.prenom[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label="CIN"
              label-for="cin"
          >
            <b-form-input
                v-model="client.cin"
                id="cin"
                placeholder="CIN"
            />
            <small v-if="errors.cin" class="text-danger">{{ errors.cin[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label="Email"
              label-for="email"
          >
            <b-form-input
                v-model="client.email"
                id="cin"
                placeholder="Email"
            />
            <small v-if="errors.email" class="text-danger">{{ errors.email[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label="Téléphone"
              label-for="telephone"
          >
            <b-form-input
                v-model="client.telephone"
                id="cin"
                placeholder="Téléphone"
            />
            <small v-if="errors.telephone" class="text-danger">{{ errors.telephone[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label="Adresse"
              label-for="adresse"
          >
            <b-form-textarea
                v-model="client.adresse"
                id="adresse"
                placeholder="Adresse"
                rows="3"
            />
            <small v-if="errors.adresse" class="text-danger">{{ errors.adresse[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col v-if="showSaveButton">
          <b-button
      
              type="submit"
              variant="primary"
              class="mr-1"
              @click="submit"
          >
            Enregistrer
          </b-button>
        
        </b-col>
      </b-row>
      
    </b-form>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
 

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  props : {
    initialClient : {
      default : false,
    },
    showSaveButton : {
      default : true
    }
  },
  methods : {
    submit(){
      let _this = this;
      _this.errors = {};
      this.$store.dispatch('clientsModule/storeClient',this.client ).then(function (response){
        _this.$emit('submited', {...response.client});
        console.log(response);
      }).catch(function(error){
        if(error.response){
          console.log(error.response.data);
          _this.errors = error.response.data.errors;
        }
        
      })
    }
  },
  created() {
    this.client = {...this.initialClient};
  },
  data() {
    return {
      client : {
        nom : '',
        prenom : '',
        civilite : ''
      },
      errors : {}
    }
  },
}
/*
<ul class="chosen-results"><li class="active-result result-selected" data-option-array-index="1" style="">MR</li><li class="active-result" data-option-array-index="2" style="">COMPANY</li><li class="active-result" data-option-array-index="3" style="">GARAGE</li><li class="active-result" data-option-array-index="4" style="">MESSRS</li><li class="active-result" data-option-array-index="5" style="">MME</li><li class="active-result" data-option-array-index="6" style="">MMES</li><li class="active-result" data-option-array-index="7" style="">MS</li></ul>
 */
</script>
