<template>
 
    <div :class="'d-flex blockquote pl-1 mb-0 border-left-3 ' +  (borderVariant ? borderVariant : 'border-left-primary') ">
      <b-img  v-if="withImage" thumbnail :src="vehicule.thumbnail + '?w=150&h=100'" blank-color="#ccc" width="150"
             alt="placeholder" class="mr-1"></b-img>
    
      <div>
        <h5 class="mt-0 mb-0 d-flex justify-content-between align-items-center">
          <div>
            {{ vehicule.marque }} {{ vehicule.modele }}
          </div>
          <div class="ml-1 font-small-1 ">{{vehicule.statut_vente.label}}</div>
        </h5>
        <h6  v-if="reprise && reprise.offre_en_cours.prix">Offre  : {{reprise.offre_en_cours.prix}} Dhs</h6>
        <h6 class="mb-0">{{ vehicule.version }}</h6>
        <h6 class="my-0 font-small-1">{{vehicule.site}}</h6>
        <div class="d-flex font-small-4">
          <div class="pr-1">
            <div class="car-icon" :style="{backgroundImage: `url(${require('@/assets/images/cars/changement-de-vitesse.png')})`}">
              {{ vehicule.type_boite }}
            </div>
            <div class="car-icon" :style="{backgroundImage: `url(${require('@/assets/images/cars/compteur-de-vitesse.png')})`}">
              {{ vehicule.kilometrage.toLocaleString('fr') }} KM
            </div>
            <div class="car-icon" :style="{backgroundImage: `url(${require('@/assets/images/cars/calendar.png')})`}">
              {{ vehicule.date_mec }}
            </div>
          </div>
          <div class="pl-1 border-left">
       
            <div class="car-icon" :style="{backgroundImage: `url(${require('@/assets/images/cars/pompe-a-carburant.png')})`}">
              {{ vehicule.energie }}
            </div>
            <div>
              {{ vehicule.vin }}
            </div>
            <div>
              {{ vehicule.immatriculation }}
            </div>
          </div>
          
        </div>
      </div>
    </div>
 

</template>

<script>
export default {
  name: "VehiculeOverview",
  props : {
    vehicule : {
      required : true
    },
    withImage : {
      default : true
    },
    clickable : {
      default : true
    },
    borderVariant : {
      default : 'border-left-primary'
    },
    reprise : {
      default(){
        return false
      }
    }
  },
  methods : {
    clickHandler(){
      if(this.clickable){
        return false;
        this.$router.push('/gestion-de-stock/' + this.vehicule.id);
      }

    }
  }
}
</script>

<style scoped>

</style>